import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
export const VideoModalContext = createContext();

export const VideoModalProvider = ({ children }) => {
  const [videoUrl, setVideoUrl] = useState("");

  return (
    <VideoModalContext.Provider
      value={{
        videoUrl,
        setVideoUrl,
      }}
    >
      {children}
    </VideoModalContext.Provider>
  );
};

VideoModalProvider.propTypes = {
  children: PropTypes.object,
};
