import React from "react";
import { VideoModalProvider } from "./src/context/videoModalContext";
import { VideoTranscriptModalProvider } from "./src/context/videoTranscriptModalContext";

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    const skipLink = document.querySelector("#reach-skip-nav");
    if (skipLink) {
      skipLink.focus();
    }
  }
};

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};

export const wrapRootElement = ({ element }) => (
  <VideoModalProvider>
    <VideoTranscriptModalProvider>{element}</VideoTranscriptModalProvider>
  </VideoModalProvider>
);
