import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const VideoTranscriptModalContext = createContext();

export const VideoTranscriptModalProvider = ({ children }) => {
  const [transcript, setTranscript] = useState("");

  return (
    <VideoTranscriptModalContext.Provider
      value={{
        transcript,
        setTranscript,
      }}
    >
      {children}
    </VideoTranscriptModalContext.Provider>
  );
};

VideoTranscriptModalProvider.propTypes = {
  children: PropTypes.object,
};
